<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          SIA
        </h2>
      </b-link> -->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-left justify-content-left px-5">
          <b-img class="w-100 pt-3" fluid :src="imgUrl" alt="Login V2" />
          <!-- <b-img class="w-25 pt-5 pl-1" fluid :src="sideImgRight" height="" alt="Login V2" /> -->
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-row>
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 pb-3 mx-auto d-sm-block d-md-none"
          >
            <b-img class="w-50" fluid :src="sideImgRight" />
          </b-col>
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
              Selamat datang di Portal Sistem Informasi Advokat
            </b-card-title>
            <b-card-text class="mb-2">
              Masukkan kombinasi email dan password yang telah terdaftar untuk
              masuk ke portal SIA.
            </b-card-text>

            <!-- <b-alert variant="primary" show>
              <div class="alert-body font-small-2">
                <p>
                  <small class="mr-50">
                    <span class="font-weight-bold">Admin:</span>
                    mantap | 123456
                  </small>
                </p>

                <p>
                  <small class="mr-50"
                    ><span class="font-weight-bold">Client:</span>
                    client@demo.com | client</small
                  >
                </p>
              </div>
              <feather-icon
                v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
                icon="HelpCircleIcon"
                size="18"
                class="position-absolute"
                style="top: 10; right: 10;"
              />
            </b-alert> -->

            <!-- form -->
            <validation-observer ref="loginForm" #default="{invalid}">
              <b-form class="auth-login-form mt-2" @submit.prevent="login">
                <!-- username -->
                <b-form-group label="Username" label-for="login-username">
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    vid="username"
                    rules="required"
                  >
                    <b-form-input
                      id="login-username"
                      v-model="userName"
                      :state="errors.length > 0 ? false : null"
                      name="login-username"
                      placeholder="username"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link :to="{ name: 'akun-forgot-password' }">
                      <small>Lupa Password?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="site_key"
                    @verify="verifyMethod"
                    @expired="expiredMethod"
                  />
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    v-if="false"
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Ingat Saya
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  Masuk
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <span>Jika sudah memiliki Nomor Induk Advokat (NIA)? </span>
              <b-link :to="{ name: 'akun-verifikasi-biodata' }">
                <span>&nbsp;Verifikasi di Sini</span>
              </b-link>
            </b-card-text>

            <!-- sementara disable registrasi member utk SAI -->
            <!-- <b-card-text class="text-center mt-2">
              <span>Belum memiliki akun SIA? </span>
              <b-link :to="{ name: 'akun-register' }">
                <span>&nbsp;Daftar di Sini</span>
              </b-link>
            </b-card-text> -->
          </b-col>
          <b-col sm="8" md="6" lg="12" class="px-xl-2 pt-4 mx-auto">
            <div class="text-center mt2">
              Didukung oleh:
            </div>
          </b-col>
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-img class="w-100" fluid :src="logoTAF" />
          </b-col>
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <div class="d-flex justify-content-center">
              <b-img class="w-50" fluid :src="logoCBDB" />
              <!-- <b-img class="w-50" fluid :src="logoTAF" /> -->
            </div>
          </b-col>
        </b-row>
        <b-overlay :show="processing" no-wrap>
          <template #overlay>
            <b-spinner variant="primary" label="Text Centered" />
          </template>
        </b-overlay>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  // BAlert,
  VBTooltip,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue'
// import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { api, setToken } from '@/hap-sia/setup'
// import store from '@/store/index'
import { VueRecaptcha } from 'vue-recaptcha'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // BAlert,
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BSpinner,
    VueRecaptcha,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userName: '',
      // sideImg: require('@/assets/images/pages/login-v2.svg'),
      sideImg: require('@/assets/images/logo/aai.png'),
      sideImgRight: require('@/assets/images/logo/aai.png'),
      logoCBDB: require('@/assets/images/logo/logo_cbdb.png'),
      logoTAF: require('@/assets/images/logo/logo_taf2.png'),

      // validation rules
      required,
      email,
      processing: false,
      recaptcha: '',
      site_key: process.env.VUE_APP_SIA_RECAPTCHA_SITEKEY,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   // this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
      //   return this.sideImg
      // }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(async success => {
        if (!success) {
          return
        }

        let userData
        try {
          this.processing = true
          const result = await api().post('akun/login', {
            username: this.userName,
            password: this.password,
            recaptcha_token: this.recaptcha,
          })
          userData = result.data
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Informasi',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${error.response.data.message}`,
            },
          })
          this.$refs.recaptcha.reset()
        }
        this.$root.fabStatus(true)
        this.processing = false
        setToken(userData.accessToken)
        // setRefreshToken(userData.refreshToken)
        userData.fullName = userData.name
        userData.role = userData.default_role
        // inject default ability
        userData.ability.push({
          action: 'read',
          subject: 'Auth',
        })
        localStorage.setItem('userData', JSON.stringify(userData))

        this.$ability.update(userData.ability)

        this.$router.replace(userData.redirect_url).then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Welcome ${userData.fullName || userData.username}`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `You have successfully logged in as ${userData.default_role}. Now you can start to explore!`,
            },
          })
        })
      })
    },
    verifyMethod(response) {
      this.recaptcha = response
    },
    expiredMethod() {
      this.recaptcha = ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
